<template lang="pug">
  .pagination__per-page(v-if="pagination.size")
    transition(name="fade")
      app-select(
        :options="DEFAULT_COUNT_LIST"
        :value="pagination.size"
        @input="setCountPerPage"
        placement="bottom-center"
        hide-arrow
        text
        :class="{delimiter: !hideDelimiter}"
        popover-classes="popover__select-per-page"
      )
        template(v-slot:before)
          span.pagination__per-page-text Items per page:
</template>

<script>
import { DEFAULT_COUNT_LIST } from '@/util/const';
import { PerPageStorage } from '@/models/perPageStorage.js';

export default {
  props: {
    pagination: {
      type: [Array, Object],
      required: true
    },
    hideDelimiter: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    DEFAULT_COUNT_LIST,
    storage: null,
  }),

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.storage = new PerPageStorage();
    },

    setCountPerPage(value) {
      this.storage.savePerPageCount(value);
      this.$emit('change', value);
    }
  },

  components: {
    appSelect: () => import('@/components/global/actions/BaseSelect.vue')
  }
}
</script>

<style lang="scss">
.pagination__per-page .app-select {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &.delimiter::after {
    content: '';
    width: 1px;
    height: 100%;
    background-color: rgb($label-color, 0.4);
    position: absolute;
    right: 8px;
  }

  .v-popover {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .error-message {
    display: none;
  }

  .app-input__wrapper {
    input {
      font-weight: bold;
      max-width: 40px;
      min-width: 40px;
      color: $new-primary;
    }
  }

  .app-input__before {
    color: $label-color;
    font-size: 11px;
  }

  .app-input__after {
    display: none;
  }
}

.popover__select-per-page {
  .app-select__option {
    min-width: 60px;
    justify-content: center;
  }
}

.pagination__per-page-text {
  @include media("<=phone") {
    display: none;
  }
}
</style>
